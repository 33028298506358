import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constantes } from './constants.property';

import { show } from '../servicesModels/show';

@Injectable({
  providedIn: 'root'
})
export class ShowService {

  constructor(private httpClient: HttpClient) { }

  public makeshow(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<show>(`${Constantes.IPserver}:${Constantes.Port}/api/shows`, obj, httpOptions)

  }
  public makeshowplace(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<show>(`${Constantes.IPserver}:${Constantes.Port}/api/places/${obj.id}/shows`, obj, httpOptions)

  }
  public updateShow(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.patch<show>(`${Constantes.IPserver}:${Constantes.Port}/api/shows/${obj.id}`, obj, httpOptions)

  }
  public getAllShows(condition, attr, value, arith, like = false) {

    let service = `${Constantes.IPserver}:${Constantes.Port}/api/shows`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<show[]>(service, httpOptions)
  }
  public getAllPlacesShows(condition, attr, value, arith, like = false) {
    let place = JSON.parse(localStorage.getItem('place'))
    let placeid;
    if (place) {
      placeid = place.id
    }
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/places/${placeid}/shows`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<show[]>(service, httpOptions)
  }
  public AllCallendar(shows) {
    let events = []
    for (let show of shows) {
      let event = <any>{};
      event.id = show.id
      // event.url = `admin/show/detail/${show.id}`
      if (show.name) {
        event.title = show.name
      }
      if (show.ShowDate) {
        event.start = show.ShowDate
      }
      if (show.ShowDateEnd) {
        event.end = show.ShowDateEnd
      }
      events = [...events, event];
      //   events.push(event);
    }
    console.log(events, 'events')

    return events

  }
  getShowById(id) {
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/shows/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<show>(service, httpOptions)
  }

}
// http://localhost:3000/api/shows?filter[where][address]=Almagro