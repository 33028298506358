import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constantes } from './constants.property';

import { Client } from '../servicesModels/Client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpClient: HttpClient) { }

  public makeClient(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<Client>(`${Constantes.IPserver}:${Constantes.Port}/api/clients`, obj, httpOptions)

  }
  public updateClient(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.patch<Client>(`${Constantes.IPserver}:${Constantes.Port}/api/clients/${obj.id}`, obj, httpOptions)

  }
  public getAllClients(condition, attr, value, arith, like = false) {

    let service = `${Constantes.IPserver}:${Constantes.Port}/api/clients`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<Client[]>(service, httpOptions)
  }

  getClientById(id) {
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/clients/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<Client>(service, httpOptions)
  }
  ClientShowCount(id){
    let service = `${Constantes.IPserver}:${Constantes.Port}/clients/${id}/shows/count`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<any>(service, httpOptions)
  }

}
// http://localhost:3000/api/clients?filter[where][address]=Almagro