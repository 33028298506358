import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// import * as moment from 'moment'
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { Calendar } from 'fullcalendar'
// import { FullCalendarModule } from 'ng-fullcalendar';
// Prime NG
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ListComponent } from './list/list.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ButtonModule } from 'primeng/button';
import { GrowlModule } from 'primeng/growl';
import { ScheduleModule } from 'primeng/schedule';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BarcodeListenerComponent } from './barcode-listener/barcode-listener.component';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';

import { NgxBarcodeModule } from 'ngx-barcode';
// PRUEBA

// import { MaterialModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import 'hammerjs';

// ANGULAR MATERIAL

import { MatInputModule, } from '@angular/material/input';
import { MatCheckboxModule, } from '@angular/material/checkbox';
import { MatSelectModule, } from '@angular/material/select';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TabMenuModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    GrowlModule,
    TableModule,
    DialogModule,
    ScheduleModule,
    CalendarModule,
    MenuModule,
    InputTextareaModule,
    NgxBarcodeModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MenubarModule
  ],
  declarations: [NavbarComponent, BarcodeListenerComponent, ListComponent],
  exports: [
    NavbarComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TabMenuModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    GrowlModule,
    TableModule,
    DialogModule,
    ScheduleModule,
    CalendarModule,
    MenuModule,
    InputTextareaModule,
    BarcodeListenerComponent,
    NgxBarcodeModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MenubarModule,
    ListComponent,
  ]

})
export class SharedModule { }
