import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
// import { DetailsComponent } from './details/details.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { ListComponent } from './list/list.component';
// import { DashboardEmpComponent } from './dashboard-emp/dashboard-emp.component';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';



// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'login',
//     pathMatch: 'full',
//   },
//   {
//     path: 'login',
//     component: LoginComponent
//   },
//   // {
//   //   path: 'details/:id',
//   //   component: DetailsComponent
//   // },
//   {
//     path: 'dashboard',
//     component: DashboardComponent
//   },
//   {
//     path: 'dashboardEmp',
//     component: DashboardEmpComponent
//   },
//   { path: '**', component: PageNotFoundComponentComponent }
// ];
const routes: Routes = [
  {
    path: 'admin',
    loadChildren: 'src/app/admin/admin.module#AdminModule'
  },
  {
    path: 'deployment',
    loadChildren: 'src/app/deployment/deployment.module#DeploymentModule'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponentComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
