import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from  '@angular/common/http';
import { Constantes } from './constants.property';

import { loginObject } from './loginObject.property';
import { User } from '../servicesModels/User';

/**
 * Angular 6 provides a way to register services/providers directly in the 
 * @Injectable() decorator by using the new providedIn attribute. 
 * This attribute accepts any module of your application or 'root' 
 * for the main app module. Now you don't have to include your service in 
 * the providers array of your module.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // const httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'Authorization': 'my-auth-token'
  //   })
  // };
 
  constructor(private  httpClient:  HttpClient) {  }
  
  // getUsers(){
  //   return this.httpClient.get('https://jsonplaceholder.typicode.com/users')
  // }

  public InicioSesion(obj: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.post<loginObject>(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers/login`, obj, httpOptions)
  }
  public logout(tk) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })

    };
    
    return this.httpClient.post<HttpResponse<User>>(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers/logout?access_token=${tk}`, null, httpOptions)
  }
  public getUser(id, tk){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.get<User>(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers/${id}?access_token=${tk}`, httpOptions)
  }
  
  public DeleteUser(id, tk){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.delete(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers/${id}?access_token=${tk}`, httpOptions)
  }

  public updateUser(obj:any,tk){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.patch<User>(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers/${obj.id}?access_token=${tk}`, obj, httpOptions)

  }
  public rregisterUser(obj: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.post<User>(`${Constantes.IPserver}:${Constantes.Port}/api/AppUsers`, obj, httpOptions)
  }





}
