import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found-component',
  templateUrl: './page-not-found-component.component.html',
  styleUrls: ['./page-not-found-component.component.css']
})
export class PageNotFoundComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
 goBack(){
  if (localStorage.token) {
    
  }
 }
}
