import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { PlaceService } from '../../services/place.service';
import { Message } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [LoginService]
})
export class NavbarComponent implements OnInit {
  items: MenuItem[];
  headers: any;
  UserName: string;
  display = false; // Variable que muestra el preloader
  disabled: boolean = true;
  msgs: Message[] = [];
  userType: any;
  allPlaces:SelectItem[];
  res:any
  place:any;
  placeOpt:any;
  constructor(private Router: Router,
    private LoginService: LoginService,
    private PlaceService:PlaceService
    ) { }

  ngOnInit() {
    this.allPlaces = [];
    this.UserName = localStorage.getItem('userName')

    this.userType= localStorage.getItem('userType')
    if(this.userType != 'Empleado'){
      this.items = [
        {
          label: 'Calendario',
          items: [{
            label: 'Ir a calendario',
            icon: 'pi pi-fw pi-calendar',
            routerLink: ['/admin']
          }
          ]
        },
        {
          label: 'Eventos',
          items: [{
            label: 'Nuevo Evento',
            icon: 'pi pi-fw pi-plus',
            routerLink: ['/admin/show/add']
          },
          {
            label: 'Lista de Eventos',
            icon: 'pi pi-fw pi-bars',
            routerLink: ['/admin/show/view']
          },
          {
            label: 'Nuevo Lugar',
            icon: 'pi pi-fw pi-bars',
            routerLink: ['/admin/show/addPlace']
          }
          ]
        },
        {
          label: 'Generales',
          items: [{
            label: 'Articulos',
            icon: 'fas fa-list',
            items: [{
              label: 'Nuevo Articulo',
              icon: 'pi pi-fw pi-plus',
              routerLink: ['/admin/item/add']
            },
            {
              label: 'Lista de Articulos',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/item/view']
            }
            ]
          },
          {
            label: 'Usuarios',
            icon: 'fas fa-box',
            items: [{
              label: 'Nuevo Usuario',
              icon: 'pi pi-fw pi-plus',
              routerLink: ['/admin/user/new']
            },
            {
              label: 'Lista de Usuarios',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/user/view']
            }
            ]
          },
          {
            label: 'Proveedor',
            icon: 'fas fa-box',
            items: [{
              label: 'Nuevo Proveedor',
              icon: 'pi pi-fw pi-plus',
              routerLink: ['/admin/supplier/add']
            },
            {
              label: 'Lista de proveedores',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/supplier/view']
            }
            ]
          },
          {
            label: 'Remito',
            icon: 'fas fa-box',
            items: [{
              label: 'Nuevo Remito',
              icon: 'pi pi-fw pi-plus',
              routerLink: ['/admin/remit/add']
            },
            {
              label: 'Lista de Remitos',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/remit/view']
            }
            ]
          },
          {
            label: 'Presupuesto',
            icon: 'fas fa-box',
            items: [
            {
              label: 'Lista de Presupuesto este año',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/budget/view']
            }
            ]
          },
          {
            label: 'Deuda',
            icon: 'fas fa-box',
            items: [
              {
                label: 'Nueva Lista de deudas',
                icon: 'pi pi-fw pi-bars',
                routerLink: ['/admin/debt/add']
              },
            {
              label: 'Listados de deudas',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/debt/detail']
            }
            ]
          },
          {
            label: 'Cargar Facturas',
            icon: 'fas fa-box',
            items: [
            {
              label: 'Ir',
              icon: 'pi pi-fw pi-bars',
              routerLink: ['/admin/invoice']
            }
            ]
          }
          ]
        },
        {
          label: this.UserName,
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Detalles', icon: 'pi pi-fw pi-user', command: (event) => {
                this.viewUser();
              }
            },
            {
              label: 'Cerrar Sesión', icon: 'pi pi-fw pi-sign-out', command: (event) => {
                this.logout();
              }
            }
          ]
        }
      ];
    } else {
      this.items = [
        {
          label: this.UserName,
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Detalles', icon: 'pi pi-fw pi-user', command: (event) => {
                this.viewUser();
              }
            },
            {
              label: 'Cerrar Sesión', icon: 'pi pi-fw pi-sign-out', command: (event) => {
                this.logout();
              }
            }
          ]
        },
        {
          label: 'Lista de Eventos',
          icon: 'pi pi-fw pi-bars',
          routerLink: ['/deployment']
        }
      ]
    }
    this.loadPlaces()
  }

  logout() {
    this.display = true;
    const tk = localStorage.getItem('token');
    this.LoginService.logout(tk).subscribe((response) => {
      console.log('LOGOUT', response)
      this.display = false;
      if (response == null) {
        this.Router.navigateByUrl('/login')
      }
    }, err => {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Error!', detail: `Ocurrio un error verifique que este conectado al internet` });
      this.display = false;
    });
  }
  viewUser() {
    const userId = localStorage.getItem('userId')
    this.Router.navigateByUrl(`admin/user/detail/${userId}`)
  }
  loadPlaces(){
    this.PlaceService.getAllplaces(null,null,null,null).subscribe(response =>{
        this.res = response

        if(this.res.length > 0){
          for(let place of this.res){
            console.log(this.res)
            // this.docType = [
            //   {label: 'DNI', value: '1'},
            //   {label: 'Documento', value:'2'}
            // ]
            let dpMenu = <any>{}
            dpMenu.label=place.nombre
            dpMenu.value=place.id
            this.allPlaces.push(dpMenu)
          }
        }
    })
  }
  getPlace(id){
    if(id){
      this.place = this.res.find(function(x){
        return x.id === id
      })
      if(typeof(this.place)=="object"){
        localStorage.setItem('place',JSON.stringify(this.place))
      }
    }
  
  }
}
