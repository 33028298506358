import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['./debt.component.css']
})
export class DebtComponent implements OnInit {
  items: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.items = [
      {label: 'Agregar', icon: 'fa fa-fw fa-bar-chart', routerLink: ['/admin/debt/add']},
      {label: 'Lista', icon: 'fa fa-fw fa-bar-chart', routerLink: ['/admin/debt/detail']}
  ];
  }

}
