import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';
import { Message } from 'primeng/api';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {
  login = [];
  users$: Object;
  msgs: Message[] = []; // Parametros del alert o mensaje que se va a mostrar.
  display = false; // Variable que muestra el preloader
  usuario: string;    // Nombre de Usuario
  contrasena: string; // Contraseña
  // Errores y Mensajes al usuario
  error1: boolean;
  error2: boolean;
  error3: boolean;
  loading = false;
  obj: any;

  constructor(private Router: Router,
    private LoginService: LoginService) {
    // try {
    //   if (localStorage.getItem('data') !== null) {
    //     this.msgs = [];
    //     this.msgs.push({ severity: 'warn', summary: '¡Atención!', detail: 'Su sesión ha expirado.' });
    //     setTimeout(() => { this.display = false }, 1000);
    //   }
    //   throw new Error('NullData');
    // } catch (error) {
    //   localStorage.clear();
    // }
  }

  ngOnInit() {
    localStorage.clear();
    // this.LoginService.getUsers().subscribe(
    //   data =>{
    //     this.users$ = data
    //     console.log ('DE ACA ME VIENEN LOS DATOS',data);
    //   } 
    // )
  }
  EvaluateIfMailUser() {

    function validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (validateEmail(this.usuario)) {
      this.obj = {
        email: this.usuario,
        password: this.contrasena
      }
      console.log('con email', this.obj);
    } else {
      this.obj = {
        username: this.usuario,
        password: this.contrasena
      }
      console.log('con username', this.obj);
    }
  }
  click() {
    this.display = true;
    this.error1 = false;
    this.error2 = false;
    // this.Router.navigateByUrl('/deployment');
    if (this.usuario == null || this.usuario.trim() == ''
      || this.contrasena == null || this.contrasena.trim() == '') {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: '¡Atención!', detail: 'Uno de los campos se encuentra vacío' });
      this.display = false
      return;
    }
    this.EvaluateIfMailUser();
    this.LoginService.InicioSesion(this.obj).subscribe((response) => {
      console.log(response.id);

      this.users$ = response;

     localStorage.setItem('token', response.id);
     localStorage.setItem('userId', response.userId.toString());
     this.LoginService.getUser(response.userId,response.id).subscribe((response)=>{
      localStorage.setItem('userType', response.type);
      localStorage.setItem('userName', response.Name);
      this.display = false;
      if(response.type == 'Admin' || response.type =='Especial'){
        this.Router.navigateByUrl('/admin');
      }
      if(response.type == 'Empleado'){
        this.Router.navigateByUrl('/deployment');
      }

     },
     err=>{
      this.display = false;
      // console.log('ESTE ES EL ERROR getUser', err);
     });
    }, err => {
      this.display = false;
      // console.log('ESTE ES EL ERROR InicioSesion', err);
    });
    
    // this.obj = this.EvaluateIfMailUser(this.usuario, this.contrasena);
    // this.LoginService.InicioSesion(obj).subscribe(
    //   datos => { this.guardardatos(datos); /*console.log(datos);*/ },
    //   err => {
    //     if (err.ok == false) {
    //       this.msgs = [];
    //       this.msgs.push({ severity: 'error', summary: '¡Ha ocurrido un error!', detail: 'Por favor, intente más tarde' });
    //       setTimeout(() => { this.display = false }, 1000);
    //     }
    //   }
    // );
  }

  guardardatos(data: any) {
    // const auxData = Seguridad.descodificar(data);
    const auxData = data;
    if (auxData.status != 200) {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: '¡Atención!', detail: auxData.message });
      this.display = false
    }
    if (auxData.status == 200) {
      let datos = JSON.parse(auxData.body);
      if (datos.token == '') {
        this.display = false;
        this.msgs = [];
        this.msgs.push({ severity: 'warn', summary: '¡Atención!', detail: 'Credenciales incorrectas' });
      } else {


        localStorage.setItem('user', this.usuario);


       


        console.log('LO QUE DEBO USAR DEL SERVICIO', datos);

      }

    }
  }
}