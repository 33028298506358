import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
// import { NgxBarcodeModule } from 'ngx-barcode';

// modulos personalizados
import {SharedModule,} from './shared';


// PRUEBA

// import { MaterialModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import 'hammerjs';

// // ANGULAR MATERIAL

// import {MatInputModule} from '@angular/material/input';
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatSelectModule} from '@angular/material/select';

// PrimeNG

// import { ButtonModule } from 'primeng/primeng';
// import { MenubarModule, MenuItem, PasswordModule, InputTextModule, DropdownModule } from 'primeng/primeng';
// import {TooltipModule} from 'primeng/primeng';
// import {RadioButtonModule} from 'primeng/primeng';
// import {TieredMenuModule} from 'primeng/primeng';
// import {MenuModule} from 'primeng/primeng';

// Components
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
// import { ListComponent } from './list/list.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardEmpComponent } from './dashboard-emp/dashboard-emp.component';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { DebtComponent } from './admin/debt/debt.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LoginComponent,
    DashboardComponent,
    DashboardEmpComponent,
    PageNotFoundComponentComponent,
    DebtComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
