import { Component, OnInit } from '@angular/core';
import { ShowService } from '../../services/show.service';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  shows: any;
  constructor(private showService: ShowService, private clientService: ClientService, private Router: Router) { }

  ngOnInit() {
    this.shows = [];
    this.showService.getAllShows('where', 'isActive', 'true', null).subscribe(response => {
      let today = new Date();
      for (let show of response) {
        if(show.clientId){
          this.clientService.getClientById(show.clientId).subscribe(response=>{
            show.auxstr1 = response.name + ' '+ response.lastName
          }, err =>{console.warn(err)})
        }
        if (show.ShowDateEnd) {
          let conversion = new Date(show.ShowDateEnd)
          if (conversion >= today) {
            this.shows.push(show);
          } 
        }else {
          let conversio = new Date(show.ShowDate)
            if(conversio >= today){
              this.shows.push(show);
            }
          }
      }
      // this.shows = response;
      console.log('response show', response);
    })
  }
  toDetail(id) {
    console.log(id)
    this.Router.navigateByUrl('/admin/show/detail/' + id);

  }
  

}
