import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constantes } from './constants.property';
import { place } from '../servicesModels/place';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {


  constructor(private httpClient: HttpClient) { }

  public makeplace(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<place>(`${Constantes.IPserver}:${Constantes.Port}/api/places`, obj, httpOptions)

  }
  public updateplace(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.patch<place>(`${Constantes.IPserver}:${Constantes.Port}/api/places/${obj.id}`, obj, httpOptions)

  }
  public getAllplaces(condition, attr, value, arith, like = false) {

    let service = `${Constantes.IPserver}:${Constantes.Port}/api/places`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<place[]>(service, httpOptions)
  }

  getplaceById(id) {
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/places/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<place>(service, httpOptions)
  }
  deleteplace(id){
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/places/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.delete<place>(service, httpOptions)
  }
}
// http://localhost:3000/api/places?filter[where][address]=Almagro